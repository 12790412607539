.ce-block{
   
}
.ce-block__content,
.ce-toolbar__content {
    max-width: unset;
    overflow: visible;
    /* Ensure content isn't hidden or cropped */
    margin-inline: 45px;

}
.ce-toolbar__settings-btn{
    color: #147AF3;
}

.codex-editor__redactor {
    padding-bottom: 0 !important;
}

#editorSession .ce-toolbar__plus {
    display: none !important;
}
.ce-popover-item__title{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: auto;
    font-family:'Inter';
}